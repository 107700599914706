<template>
  <DashboardContainer>
    <TeamsGrid />
  </DashboardContainer>
</template>

<script>
import DashboardContainer from "../containers/DashboardContainer";
import TeamsGrid from "../components/teams/TeamsGrid";

export default {
  name: "Teams",
  beforeCreate: function () {
    var auth = this.$storage.get("auth");
    if (!auth) {
      this.$router.push("/login");
    } else {
      if (!auth.token) {
        this.$router.push("/login");
      }
    }
  },
  components: {
    DashboardContainer,
    TeamsGrid,
  },
};
</script>