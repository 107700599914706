<template>
  <!-- saleteam-area -->
  <div class="saleteam-area">
    <!-- teams -->
    <div class="teams" v-for="(team, index) in teams" :key="index">
      <div class="delete-member blue-circle" v-on:click="deleteTeamModal(team)">
        <img class="img-fluid" src="../../assets/img/Delete.svg" alt="" />
      </div>
      <div
        class="edit-member blue-circle"
        v-on:click="updateTeamModal(team, index)"
      >
        <img class="img-fluid" src="../../assets/img/pencil.svg" alt="" />
      </div>
      <div class="team-img">
        <router-link
          style="color: black; font-weight: 800"
          :to="{ name: 'Members', params: { slug: team.tm_slug } }"
          ><img class="img-fluid" src="../../assets/img/profile.svg" alt=""
        /></router-link>
      </div>
      <div class="team-title">
        <router-link
          style="color: black; font-weight: 800"
          :to="{ name: 'Members', params: { slug: team.tm_slug } }"
          >{{ team.tm_name }}</router-link
        >
      </div>
    </div>
    <div class="teams">
      <div class="add-new">
        <a
          href="#"
          @click="openTeamModal"
          data-backdrop="static"
          data-keyboard="false"
          ><img class="img-fluid" src="../../assets/img/240.svg" alt=""
        /></a>
      </div>
    </div>
    <!-- teams -->
    <!-- Add tema-->
    <div
      class="modal fade"
      id="addTeamModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addTeamModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addTeamModalLabel">Add New Team</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="addNewTeam">
            <div class="modal-body">
              <div class="add-member">
                <div class="form-group">
                  <label for="teamName">Team Name</label>
                  <input
                    required
                    type="text"
                    v-model="teamName"
                    class="form-control"
                    id="teamName"
                  />
                  <p style="color: red">{{ error }}</p>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <!-- <a href="#" class="add-btn"><img class="img-fluid" src="../../assets/img/22. Delete.svg" alt=""> Cancel</a> -->
              <button type="submit" class="rem-btn">
                <img
                  class="img-fluid"
                  src="../../assets/img/23. Close.svg"
                  alt=""
                />
                Add
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="updateTeamModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="addTeamModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addTeamModalLabel">Edit Team Name</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="updateTeam">
            <div class="modal-body">
              <div class="add-member">
                <div class="form-group">
                  <label for="teamName">Team Name</label>
                  <input
                    required
                    type="text"
                    v-model="teamName"
                    class="form-control"
                    id="teamName"
                  />
                  <p style="color: red">{{ error }}</p>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <!-- <a href="#" class="add-btn"><img class="img-fluid" src="../../assets/img/22. Delete.svg" alt=""> Cancel</a> -->
              <button type="submit" class="rem-btn">Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="delTeam"
      role="dialog"
      aria-labelledby="customeralredyexist"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Before deleting assign this team agents to:
            </h5>
            <a
              class="delas-colse close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="modal-body">
            <div class="perfor-search">
              <div class="form-row">
                <div class="form-group add-member col-md-12">
                  <autocomplete
                    ref="autocomplete"
                    placeholder="Search Team"
                    :source="
                      base_url + 'getteamsByAgent' + '/' + this.tm_id + '/'
                    "
                    results-property="data"
                    :results-display="formattedAutocompleteDisplay"
                    :request-headers="autocompleteAuthHeaders"
                    @selected="selectCustomer"
                  >
                  </autocomplete>
                  <!-- <input type="search" class="form-control" id="exampleInputEmail1" placeholder="Seach by agent or team " /><img class="img-fluid per-sericon" src="../../assets/img/search.svg" alt="" /> -->
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button @click="deleteteam" :disabled="btnLoader" class="rem-btn">
              <span v-if="btnLoader"
                ><span class="spinner-grow spinner-grow-sm"></span>
                Loading...</span
              >
              <span v-if="!btnLoader"> Delete With Agents</span>
            </button>
            <button
              @click="reassignTeam"
              :disabled="reassignbtnLoader"
              class="rem-btn"
            >
              <span v-if="reassignbtnLoader"
                ><span class="spinner-grow spinner-grow-sm"></span>
                Loading...</span
              >
              <span v-if="!reassignbtnLoader"> Reassingn Team And Delete</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import VueDaval from "vue-daval";
import Autocomplete from "vuejs-auto-complete";
export default {
  name: "MembersGrid",
  components: { Autocomplete },
  mixins: [VueDaval],
  data() {
    return {
      btnLoader: false,
      reassignbtnLoader: false,
      newteamid: "",
      base_url: axios.defaults.baseURL,
      search: "",
      teams: [],
      teamName: "",
      error: "",
      tm_id: "",
    };
  },
  vdRules: {
    teamName: { required: true },
  },
  created() {
    this.getTeams();
  },
  mounted() {},
  watch: {},
  computed: {
    autocompleteAuthHeaders() {
      return { Authorization: "Bearer " + this.$storage.get("auth").token };
    },
  },
  methods: {
    deleteteam() {
      this.btnLoader = true;
      axios
        .post(
          "deleteteam",
          { id: this.tm_id },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            this.btnLoader = false;
            $("#delTeam").modal("hide");
            this.getTeams();
            this.$toast.open({
              position: "top-right",
              message: "Team Deleted Successfully",
              type: "success",
            });
          } else {
            this.$toast.open({
              position: "top-right",
              message: "Something Wrong",
              type: "info",
            });
          }
        })
        .catch((err) => {
          this.btnLoader = false;
          console.log(err);
        });
    },
    reassignTeam() {
      this.reassignbtnLoader = true;
      axios
        .post(
          "reassignteam",
          { id: this.tm_id, newteamid: this.newteamid },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            this.reassignbtnLoader = false;
            $("#delTeam").modal("hide");
            this.getTeams();
            this.$toast.open({
              position: "top-right",
              message: "Team Reassigned Successfully",
              type: "success",
            });
          } else {
            this.$toast.open({
              position: "top-right",
              message: "Something Wrong",
              type: "info",
            });
          }
        })
        .catch((err) => {
          this.reassignbtnLoader = false;
          console.log(err);
        });
    },
    formattedAutocompleteDisplay(result) {
      return result.text;
    },
    selectCustomer(result) {
      console.log(result.selectedObject);
      this.newteamid = result.selectedObject.id;
    },
    updateTeamModal(team, index) {
      var indexx = index;
      this.tm_id = team.tm_id;
      this.teamName = this.teams[indexx].tm_name;
      $("#updateTeamModal").modal("show");
    },
    updateTeam() {
      this.$vd.teamName
        .$validate()
        .then(() => {
          axios
            .post(
              "updateTeamName",
              { name: this.teamName, id: this.tm_id },
              {
                headers: {
                  Authorization: "Bearer " + this.$storage.get("auth").token,
                  "X-Requested-With": "XMLHttpRequest",
                },
              }
            )
            .then((res) => {
              if (res.data.success == false) {
                this.error = res.data.message;
                return false;
              }
              if (res.data.success) {
                //console.log(res.data.success)
                $("#updateTeamModal").modal("hide");
                this.teamName = "";
                this.getTeams();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    deleteTeamModal(team) {
      this.tm_id = team.tm_id;
      $("#delTeam").modal("show");
    },
    openTeamModal() {
      $("#addTeamModal").modal("show");
      $("#addTeamModal").appendTo("body");
    },
    addNewTeam() {
      this.$vd.teamName
        .$validate()
        .then(() => {
          axios
            .post(
              "addTeam",
              { name: this.teamName },
              {
                headers: {
                  Authorization: "Bearer " + this.$storage.get("auth").token,
                  "X-Requested-With": "XMLHttpRequest",
                },
              }
            )
            .then((res) => {
              if (res.data.success == false) {
                this.error = res.data.message;
                return false;
              }
              if (res.data.success) {
                //console.log(res.data.success)
                this.getTeams();
                $("#addTeamModal").modal("hide");
                this.$toast.open({
                  position: "top-right",
                  message: "Team Added Successfully",
                  type: "success",
                });
                //this.$root.$emit("newTeam", res.data.success);
                this.teamName = "";
                // this.$router.push({
                //   name: "Members",
                //   params: { slug: res.data.success.tm_slug },
                // });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    getTeams() {
      axios
        .get("teams", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then((res) => {
          this.teams = res.data.data;

          //console.log(res)
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.teams {
  height: 208px;
}
.close {
  position: relative;
  top: 4px;
}
</style>